<template>
  <div>
    <CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar">
            <img :src="$auth.user.picture" class="c-avatar-img" />
          </div>
          <span class="ml-1">{{ $auth.user.name }}</span>
        </CHeaderNavLink>
      </template>

      <CDropdownHeader class="text-center" color="light">
        <strong>バージョン: {{ require("../../package.json").version }}</strong>
      </CDropdownHeader>
      <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>設定</strong>
      </CDropdownHeader>
      <CDropdownItem :to="{ name: 'プロフィール' }">プロフィール</CDropdownItem>
      <CDropdownItem @click="openPasswordModal">パスワード変更</CDropdownItem>

      <CDropdownDivider />
      <CDropdownItem :to="{ name: 'JWT' }">JWT</CDropdownItem>
      <CDropdownItem v-show="isDevelop" :to="{ name: 'ユーザ復元' }">ユーザ復元(localのみ表示)</CDropdownItem>

      <CDropdownDivider />

      <CDropdownItem @click="logout">ログアウト</CDropdownItem>
    </CDropdown>

    <ChangePasswordModal :open-modal.sync="isOpenPasswordModal" />
  </div>
</template>

<script>
import ChangePasswordModal from "@/components/modal/ChangePasswordModal";
export default {
  name: "TheHeaderDropdownAccount",
  components: { ChangePasswordModal },
  data() {
    return {
      isOpenPasswordModal: false,
    };
  },
  computed: {
    isDevelop() {
      return process.env.VUE_APP_ENV === "develop";
    },
  },
  methods: {
    logout() {
      this.$auth.logout({
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
      });
    },
    openPasswordModal() {
      this.isOpenPasswordModal = true;
    },
  },
};
</script>
