<template>
  <div>
    <LoadingAlert v-if="isFirstLoading" />
    <CCard v-else>
      <CCardHeader>
        {{ total_count }} 件の薬局を表示
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" :to="{ name: '薬局新規作成' }">
            <CIcon name="cil-plus" />新規作成する
          </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm @submit.prevent="searchPharmacies">
          <CInput v-model="query" placeholder="薬局名で検索">
            <template #append><CButton type="submit" color="info">検索</CButton></template>
          </CInput>
        </CForm>
        <CDataTable
          hover
          bordered
          fixed
          responsive
          :loading="isLoading"
          :items="pharmacies"
          :fields="fields"
          :sorter="sorter"
          :sorter-value.sync="sorterValue"
        >
          <template #name="{item : pharmacy}">
            <td>
              <CLink :to="{ name: '薬局情報', params: { id: pharmacy.id } }">{{ pharmacy.name }}</CLink>
            </td>
          </template>
          <template #company_id="{item : pharmacy}">
            <td>
              <CLink v-if="pharmacy.company_id" :to="{ name: '薬局会社情報', params: { id: pharmacy.company_id } }">
                {{ pharmacy.company_id }}
              </CLink>
            </td>
          </template>
          <template #actions="{item : pharmacy}">
            <td>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :to="{ name: '薬局情報', params: { id: pharmacy.id } }">
                詳細
              </CButton>
              <CButton color="primary" class="mr-1 mb-1" size="sm" :to="{ name: '薬局編集', params: { id: pharmacy.id } }">
                編集
              </CButton>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :href="medorder_url + '/pharmacies/' + pharmacy.id">
                メドオーダー
              </CButton>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :to="{ name: 'NSIPS一覧（薬局）', params: { id: pharmacy.id } }">
                NSIPS
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          v-show="pharmacies.length"
          align="center"
          responsive
          :active-page.sync="current_page"
          :pages="total_pages"
          :limit="10"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import LoadingAlert from "src/components/shared/LoadingAlert";

export default {
  name: "Pharmacies",
  components: { LoadingAlert },
  data() {
    return {
      isFirstLoading: true,
      isLoading: false,
      query: null,
      pharmacies: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "company_id", label: "薬局会社ID" },
        { key: "name", label: "薬局名" },
        { key: "address1", label: "都道府県" },
        { key: "updated_at", label: "更新日" },
        { key: "actions", label: "操作", sorter: false },
      ],
      sorter: { external: true, resetable: false },
      sorterValue: { column: "updated_at", asc: false },
      current_page: 1,
      total_pages: 1,
      total_count: 0,
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
    };
  },
  computed: {
    api_fields() {
      return this.fields.map((f) => f.key).filter((f) => f.key !== "actions");
    },
    sort() {
      return this.sorterValue.asc ? this.sorterValue.column : `-${this.sorterValue.column}`;
    },
  },
  watch: {
    $route() {
      this.current_page = parseInt(this.$route.query.page) || 1;
    },
    current_page(page) {
      if (!this.$route.query.page || parseInt(this.$route.query.page) !== page) {
        this.$router.push({ query: { page: `${page}` } });
      }
      this.fetchPharmacies();
    },
    sorterValue() {
      this.fetchPharmacies();
    },
  },
  async created() {
    this.current_page = parseInt(this.$route.query.page) || 1;
    await this.fetchPharmacies();
    this.isFirstLoading = false;
  },
  methods: {
    async fetchPharmacies() {
      if (this.isLoading) return;

      this.isLoading = true;
      const fields = this.api_fields.join(",");
      const req_params = { page: this.current_page, query: this.query, sort: this.sort, fields: fields };
      const response = await this.$axios.get("/api/v2/core/admin/pharmacies", { params: req_params });
      this.pharmacies = response.data;
      this.current_page = Number(response.headers["x-current-page"]);
      this.total_pages = Number(response.headers["x-total-pages"]);
      this.total_count = Number(response.headers["x-total-count"]);
      this.isLoading = false;
    },
    async searchPharmacies() {
      this.current_page = 1;
      this.fetchPharmacies();
    },
  },
};
</script>
