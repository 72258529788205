import Axios from "axios";
import { getAuth0JwtToken } from "@/store/auth0Store";

export function createHttp(host: string) {
  const http = Axios.create({});

  http.interceptors.request.use(async (request) => {
    // Authorization 付与
    const jwtToken = await getAuth0JwtToken();
    request.headers.Authorization = `Bearer ${jwtToken}`;

    // URL にホスト名を付与
    request.url = host + request.url;

    return request;
  });

  return http;
}
