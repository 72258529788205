<template>
  <LoadingAlert v-if="!jwtToken" />
  <CRow v-else>
    <CCol col="12">
      <CAlert :show.sync="expireCountDown" color="info" fade>
        トークンの有効期限： {{ expString }} （{{ expireCountDown | hhmmss }}）
      </CAlert>
      <CAlert :show="expireCountDown <= 0" color="danger" fade>
        トークンの有効期限が切れています。
      </CAlert>
    </CCol>

    <CCol col="12">
      <CButton v-clipboard:copy="jwtToken" v-clipboard:success="onSuccessCopyJwt" class="mb-2" color="secondary">
        {{ copyButtonText }}
      </CButton>
      <CButton class="mb-2 ml-3" color="secondary" @click="refreshToken()">
        <v-icon name="sync" :spin="!jwtToken" />
        Tokenリフレッシュ
      </CButton>
      <CLink class="float-right" href="https://jwt.io/" target="_blank">Json Web Token - https://jwt.io/</CLink>
    </CCol>

    <CCol md="6">
      <div class="h1">JWT Token</div>
      <CCard>
        <CCardBody>
          <pre>{{ jwtToken }}</pre>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol md="6">
      <div class="h1">Decoded</div>

      <CCard class="mb-1">
        <CCardHeader>Header</CCardHeader>
        <CCardBody>
          <pre>{{ header }}</pre>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader>Payload</CCardHeader>
        <CCardBody>
          <pre>{{ payload }}</pre>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getInstance } from "src/auth";
import * as jwt from "jsonwebtoken";
import * as moment from "moment";
import LoadingAlert from "src/components/shared/LoadingAlert";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: "Jwt",
  components: { LoadingAlert },
  filters: {
    hhmmss(value) {
      if (typeof value !== "number") throw "数値を渡してください";
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = Math.floor(value % 60);
      return `${hours}時間${minutes}分${seconds}秒`;
    },
  },
  data() {
    return {
      jwtToken: null,
      copyButtonText: "JWTをコピー",
      expireCountDown: 0,
    };
  },
  computed: {
    header() {
      const decoded = jwt.decode(this.jwtToken, { complete: true });
      return JSON.stringify(decoded.header, null, 2);
    },
    payload() {
      const decoded = jwt.decode(this.jwtToken, { complete: true });
      return JSON.stringify(decoded.payload, null, 2);
    },
    exp() {
      const decoded = jwt.decode(this.jwtToken, { complete: true });
      return decoded.payload.exp;
    },
    expString() {
      return moment.unix(this.exp).format();
    },
  },
  async created() {
    this.jwtToken = await getInstance().getJwtToken();
    this.expireCountDown = this.exp - moment().unix();
  },
  methods: {
    async onSuccessCopyJwt() {
      const origin = this.copyButtonText;
      this.copyButtonText = "コピーしました";
      await sleep(1500);
      this.copyButtonText = origin;
    },
    async refreshToken() {
      this.jwtToken = null;
      await getInstance().getTokenSilently({ ignoreCache: true });
      this.jwtToken = await getInstance().getJwtToken();
      this.expireCountDown = this.exp - moment().unix();
    },
  },
};
</script>

<style>
pre {
  white-space: pre-wrap;
}
</style>
